<template>
  <div>
    <section class="d-flex justify-content-between heading-page">
      <NbPageTitle
        class="left-side"
        titleClass="heading-3"
        subTitleClass="body-3"
        :title="$t('productsPage.title')"
        :subTitle="$t('productsPage.subTitle')"
      />
      <div class="right-side">
        <div class="heading-3 mb-3">
          {{ $t("productsPage.newProductTemplate") }}
        </div>
        <NbButton
          class="w-100 text-left mb-2"
          icon="plus"
          data-toggle="modal"
          data-target="#modalAddManual"
          @click="resetNewProduct()"
        >
          {{ $t("productsPage.addProduct") }}
        </NbButton>
        <NbButton
          class="w-100 text-left mb-2"
          icon="arrow-down"
          data-toggle="modal"
          data-target="#modalImport"
        >
          {{ $t("productsPage.import") }}
        </NbButton>
      </div>
    </section>

    <section>
      <NbTablev2
        tableOf="productsPage"
        namespace="products"
        ref="productsPage"
        :allFields="allFields"
        :fields="fields"
        :vistaBtn="true"
        :tabs="false"
        :selectable="true"
        :optionsWidth="180"
        :buttonOptions="productsOptions"
        @optionTook="optionTook($event)"
        @selectedItems="selectedItems"
        @reloadFields="fields = $event"
        @total="$emit('total', $event)"
      >
        <template #cell(origin_country)="data">
          <img
            v-if="data.item.origin_country"
            :src="getFlag(data.item.origin_country)"
            width="24"
            height="18"
          />
          {{ switchCountryName(data.item.origin_country) }}
        </template>
        <template #cell(updated_at)="data">
          {{ formateDate(data.item.updated_at) }}
        </template>
        <template #cell(dimensions)="data">
          {{ data.item.width ? standardizeValue(data.item.width) : "..."
          }}{{ data.item.width ? measurementSystem.diameter : "" }} x
          {{ data.item.height ? standardizeValue(data.item.height) : "..."
          }}{{ data.item.height ? measurementSystem.diameter : "" }} x
          {{ data.item.length ? standardizeValue(data.item.length) : "..."
          }}{{ data.item.length ? measurementSystem.diameter : "" }}
        </template>
        <template #vista(dimensions)>
          <div>
            {{ $t("width") }} x {{ $t("height") }} x
            {{ $t("length") }}
          </div>
        </template>
        <template #cell(description)="data">
          <div
            v-if="data.item.description && data.item.description.length > 22"
            :id="`${data.item.id}-desc`"
          >
            {{ displayMaxCharacters(data.item.description, 22) }}
            <b-popover
              placement="top"
              :target="`${data.item.id}-desc`"
              triggers="hover focus"
              variant="dark"
            >
              {{ data.item.description }}
            </b-popover>
          </div>
          <div v-else>
            {{ data.item.description }}
          </div>
        </template>
        <template #cell(weight)="data">
          {{ standardizeValue(data.item.weight)
          }}{{ data.item.weight ? measurementSystem.weight : "" }}
        </template>

        <template #cell(actions)="data">
          <NbButton
            variant="tertiary"
            icon="edit"
            data-toggle="modal"
            data-target="#modalAddManual"
            @click="setProduct(data.item)"
          >
            {{ $t("edit") }}
          </NbButton>
          <NbButton
            variant="tertiary"
            icon="trash"
            data-toggle="modal"
            data-target="#modalDeleteProduct"
            @click="productToDeleteId = data.item.id"
          >
            {{ $t("delete") }}
          </NbButton>
        </template>
      </NbTablev2>
    </section>

    <!-- modals -->
    <NbModal id="modalImport" modalConfig="modal-dialog-centered" width="500px">
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("productsPage.import") }}
          <div class="body-4">
            {{ $t("productsPage.browseFilesToUpload") }}
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="d-flex">
          <i class="fas fa-cloud-upload-alt fa-2x mb-1"></i>
          <p class="mt-1 ml-2">{{ $t("productsPage.selectCsv") }}</p>
        </div>
        <div class="mt-1">
          <a
            class="link-1"
            href="/products-import-template.csv"
            target="_blank"
            >{{ $t("productsPage.downloadHere") }}</a
          >
          {{ $t("productsPage.csvFile") }}
        </div>
        <input
          type="file"
          class="d-none"
          ref="uploadProducts"
          accept=".csv"
          v-on:change="uploadProducts"
        />
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton
            data-backdrop="false"
            v-on:click="$refs.uploadProducts.click()"
          >
            {{ $t("upload") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal
      id="modalAddManual"
      modalConfig="modal-dialog-centered"
      width="600px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{
            new_product.id
              ? $t("productsPage.updateProduct")
              : $t("productsPage.createProductTitle")
          }}
        </div>
      </template>

      <template v-slot:body>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            :required="true"
            id="sku"
            :helpText="$t('productsPage.skuHelptext')"
            class="w-16"
            :name="$t('sku')"
            :placeholder="$t('sku')"
            :error="validationErrors['sku']"
            v-model="new_product.sku"
          />
          <NbTextInput
            id="name"
            :helpText="$t('productsPage.nameHelptext')"
            class="w-50"
            :name="$t('name')"
            :placeholder="$t('name')"
            :error="validationErrors['name']"
            v-model="new_product.name"
          />
          <NbTextInput
            id="description"
            :helpText="$t('productsPage.descriptionHelptext')"
            class="w-30"
            :name="$t('description')"
            :placeholder="$t('description')"
            :error="validationErrors['description']"
            v-model="new_product.description"
          />
          <NbCountrySelectInput
            id="origin_country"
            class="w-38"
            :name="$t('originCountry')"
            :placeholder="$t('originCountry')"
            :error="validationErrors['origin_country']"
            v-model="new_product.origin_country"
          />
          <NbSelectInput
            id="category"
            :helpText="$t('productsPage.categoryHelptext')"
            class="w-38"
            :name="$t('category')"
            :options="categoryOptions"
            :placeholder="$t('category')"
            @input="updateProductHSCode(new_product.category)"
            :error="validationErrors['category']"
            v-model="new_product.category"
          />
          <NbTextInput
            id="hs_code_input"
            :helpText="$t('productsPage.hs_codeHelptext')"
            :helpTextLink="{
              href: 'https://www.trade.gov/harmonized-system-hs-codes',
              text: 'Here >',
            }"
            class="w-20"
            :name="$t('hsCode')"
            placeholder="123456"
            :disabled="!checkProductCategory(new_product.category)"
            :error="validationErrors['hs_code']"
            v-model="new_product.hs_code"
          />
          <NbTextInput
            id="weight"
            class="w-24"
            type="number"
            :append="measurementSystem.weight"
            :name="`${$t('weight')} (${measurementSystem.weight})`"
            :decimals="3"
            step="0.001"
            :placeholder="`0.000 ${measurementSystem.weight}`"
            :autoSelect="true"
            :error="validationErrors['weight']"
            v-model="new_product.weight"
          />
          <NbTextInput
            id="height"
            class="w-24"
            type="number"
            :append="measurementSystem.diameter"
            :name="`${$t('height')} (${measurementSystem.diameter})`"
            :decimals="2"
            step="0.01"
            :placeholder="`0.00 ${measurementSystem.diameter}`"
            :autoSelect="true"
            :error="validationErrors['height']"
            v-model="new_product.height"
          />
          <NbTextInput
            id="width"
            class="w-24"
            type="number"
            :append="measurementSystem.diameter"
            :name="`${$t('width')} (${measurementSystem.diameter})`"
            :decimals="2"
            step="0.01"
            :placeholder="`0.00 ${measurementSystem.diameter}`"
            :min="0.0"
            :autoSelect="true"
            :error="validationErrors['width']"
            v-model="new_product.width"
          />
          <NbTextInput
            id="length"
            class="w-24"
            type="number"
            :append="measurementSystem.diameter"
            :name="`${$t('length')} (${measurementSystem.diameter})`"
            :decimals="2"
            step="0.01"
            :placeholder="`0.00 ${measurementSystem.diameter}`"
            :autoSelect="true"
            :error="validationErrors['length'] || []"
            v-model="new_product.length"
          />
        </div>
      </template>

      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton data-backdrop="false" @click="sendProductForm()">
            {{ $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <NbModal
      id="modalDeleteProduct"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("delete") }}
        </div>
      </template>
      <template v-slot:body>
        {{ $t("confirmDelete", { val: "" }) }}
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="removeProduct(productToDeleteId)">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal
      id="modalMassiveDeleteProductTitle"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("delete") }}
        </div>
      </template>
      <template v-slot:body>
        {{ $t("confirmDelete", { val: rowSelection.length }) }}
        {{ $tc("productsPage.found", rowSelection.length) }}?
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="massiveDelete()">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- end modals -->
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import moment from "moment";
import NProgress from "nprogress";

import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbCountrySelectInput from "@/components/input/country/NbCountrySelectInput.vue";

import ProductService from "../../services/ProductService";
import CountryService from "../../services/CountryService";

const productService = new ProductService();
const countryService = new CountryService();

export default {
  name: "Products",
  components: {
    NbPageTitle,
    NbButton,
    NbTablev2,
    NbModal,
    NbTextInput,
    NbCountrySelectInput,
    NbSelectInput,
  },
  props: {
    measurementSystem: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      quickStartLoaded: {
        hs_code: null,
        origin_country: null,
        category: null,
      },
      countries: [],
      rowSelection: [],
      new_product: {
        name: null,
        origin_country: null,
        hs_code: null,
        sku: null,
        description: null,
        category: null,
        width: null,
        length: null,
        height: null,
        weight: null,
        dimensions: null,
      },
      validationErrors: [],
      isoCountries: [],
      fields: [],
      productToDeleteId: null,
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("l");
    },
  },
  beforeMount() {
    this.getCountries();
  },
  methods: {
    getCountries() {
      countryService.getCountries().then((response) => {
        this.countries = response.data.data;
        this.isoCountries = this.countries.map(({ alpha2_code, name }) => ({
          value: alpha2_code,
          text: name,
        }));
      });
    },
    selectedItems(event) {
      this.rowSelection = event;
    },
    massiveDownload() {
      const rows = this.rowSelection;

      productService
        .downloadProducts(rows)
        .then((response) => {
          let universalBOM = "\uFEFF";
          let csv =
            "data:text/csv; charset=utf-8," +
            encodeURIComponent(universalBOM + response.data);
          let filename = "product-" + moment().format("YYYY-MM-DD") + ".csv";
          let anchor = document.createElement("a");
          anchor.href = csv;
          anchor.target = "_blank";
          anchor.download = filename;
          anchor.click();
        })
        .catch((erro) => {
          NProgress.done();
          this.$helpers.toast(
            erro.message || erro.msg || "Request failed",
            "danger"
          );
        });
    },
    uploadProducts(e) {
      let product = e.target.files[0];
      let fd = new FormData();
      fd.append("file", product);
      productService
        .uploadProducts(fd)
        .then((response) => {
          this.$helpers.closeModal("modalImport");
          this.response_message = response.data.data.messages;
          const data = response.data.data;
          this.$router.replace({
            name: "log_product_import",
            params: { data },
          });
        })
        .catch((error) => {
          NProgress.done();
          if (
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.$helpers.toast(error.response.data.messages[0], "error", 2000);
            return;
          }
          this.$helpers.toast(
            "An error has ocurred: check your .CSV file and try again.",
            "danger",
            2000
          );
        });
    },
    async massiveDelete() {
      const ids = this.rowSelection;
      await productService
        .removeMassiveProduct(ids.join(","))
        .then(() => {
          this.$refs.productsPage.getData();
          this.$helpers.closeModal("modalMassiveDeleteProductTitle");
        })
        .catch(() => {
          NProgress.done();
          this.$helpers.toast(
            "An error has ocurred: reload this page and try again.",
            "danger",
            3000
          );
        });
    },
    removeProduct(productId) {
      if (productId) {
        productService.removeProduct(productId).then(() => {
          this.$refs.productsPage.getData();
          this.$helpers.closeModal("modalDeleteProduct");
        });
      }
    },
    sendProductForm() {
      if (this.new_product.id) {
        this.editProduct();
        return;
      }
      this.createProduct();
    },
    createProduct() {
      this.pFloat(this.new_product);
      productService
        .createProduct(this.new_product)
        .then(() => {
          this.$refs.productsPage.getData();
          this.$helpers.closeModal("modalAddManual");
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") ||
            error.response.hasOwnProperty("data") ||
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }
        });
    },
    editProduct() {
      this.pFloat(this.new_product);
      productService
        .updateProduct(this.new_product.id, this.new_product)
        .then(() => {
          this.$refs.productsPage.getData();
          this.$helpers.closeModal("modalAddManual");
        })
        .catch((error) => {
          NProgress.done;
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }
        });
    },
    resetNewProduct() {
      this.new_product = {
        name: null,
        origin_country: null,
        hs_code: null,
        sku: null,
        description: null,
        category: null,
        width: null,
        length: null,
        height: null,
        weight: null,
        dimensions: null,
      };
      this.new_product.hs_code = this.quickStartLoaded.hs_code;
      this.new_product.origin_country = this.quickStartLoaded.origin_country;
      this.new_product.category = this.quickStartLoaded.category;
      this.validationErrors = [];
    },
    pFloat(new_product) {
      const arr = ["width", "height", "length", "weight"];
      arr.forEach((item) => {
        if (new_product[item]) {
          if (typeof new_product[item] === "string") {
            new_product[item] = parseFloat(new_product[item].replace(",", "."));
          }
        }
      });
      return null;
    },
    setProduct(product) {
      this.validationErrors = [];
      this.new_product = {
        name: null,
        origin_country: null,
        hs_code: null,
        sku: null,
        description: null,
        category: null,
        width: null,
        length: null,
        height: null,
        weight: null,
        dimensions: null,
      };
      this.new_product = product;
    },
    switchCountryName(countryCode) {
      let finalCountry = " - ";
      if (countryCode) {
        if (countryCode.length == 2) {
          this.isoCountries.forEach((element) => {
            if (element.value === countryCode) {
              finalCountry = element.text;
              return;
            }
          });
        } else {
          this.isoCountries.forEach((element) => {
            if (element.text === countryCode) {
              finalCountry = element.value;
              return;
            }
          });
        }
      }
      return finalCountry;
    },
    standardizeValue(val) {
      let parsed = " - ";
      if (val) {
        parsed = parseFloat(val).toFixed(2);
      }
      return parsed;
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate =
        data.getFullYear() +
        "-" +
        this.addZero(data.getMonth() + 1).toString() +
        "-" +
        this.addZero(data.getDate().toString());
      return formatedDate;
    },
    optionTook(event) {
      this[event]();
    },
    updateProductHSCode(product) {
      const newHSCode = this.HS_CODES[product];
      if (product !== "Others" && newHSCode !== this.new_product.hs_code) {
        this.new_product.hs_code = newHSCode;
      }
    },
    checkProductCategory(category) {
      return category && category === "Others"; //if simplificado
    },
    getFlag(iso) {
      if (iso) {
        return `https://flagcdn.com/24x18/${iso.toLowerCase()}.png`;
      }
    },
    displayMaxCharacters(word, maxChars) {
      if (word.length > maxChars) {
        return word.substring(0, maxChars - 5) + "(...)";
      }
      return word;
    },
  },
  computed: {
    categoryOptions() {
      return [
        {
          text: this.$t("hsCodes.acessoryNoBattery"),
          value: this.$t("hsCodes.acessoryNoBattery"),
        },
        {
          text: this.$t("hsCodes.acessory"),
          value: this.$t("hsCodes.acessory"),
        },
        {
          text: this.$t("hsCodes.audioVideo"),
          value: this.$t("hsCodes.audioVideo"),
        },
        {
          text: this.$t("hsCodes.bagsLuggages"),
          value: this.$t("hsCodes.bagsLuggages"),
        },
        {
          text: this.$t("hsCodes.boardGames"),
          value: this.$t("hsCodes.boardGames"),
        },
        {
          text: this.$t("hsCodes.booksCollectibles"),
          value: this.$t("hsCodes.booksCollectibles"),
        },
        { text: this.$t("hsCodes.cameras"), value: this.$t("hsCodes.cameras") },
        {
          text: this.$t("hsCodes.computersLaptops"),
          value: this.$t("hsCodes.computersLaptops"),
        },
        {
          text: this.$t("hsCodes.documents"),
          value: this.$t("hsCodes.documents"),
        },
        {
          text: this.$t("hsCodes.dryFoodsSupplements"),
          value: this.$t("hsCodes.dryFoodsSupplements"),
        },
        { text: this.$t("hsCodes.fashion"), value: this.$t("hsCodes.fashion") },
        { text: this.$t("hsCodes.gaming"), value: this.$t("hsCodes.gaming") },
        {
          text: this.$t("hsCodes.healthBeauty"),
          value: this.$t("hsCodes.healthBeauty"),
        },
        {
          text: this.$t("hsCodes.homeAppliances"),
          value: this.$t("hsCodes.homeAppliances"),
        },
        {
          text: this.$t("hsCodes.homeDecor"),
          value: this.$t("hsCodes.homeDecor"),
        },
        { text: this.$t("hsCodes.jewelry"), value: this.$t("hsCodes.jewelry") },
        {
          text: this.$t("hsCodes.mobilePhone"),
          value: this.$t("hsCodes.mobilePhone"),
        },
        {
          text: this.$t("hsCodes.petAccessory"),
          value: this.$t("hsCodes.petAccessory"),
        },
        { text: this.$t("hsCodes.shoes"), value: this.$t("hsCodes.shoes") },
        {
          text: this.$t("hsCodes.sportLeisures"),
          value: this.$t("hsCodes.sportLeisures"),
        },
        { text: this.$t("hsCodes.tablets"), value: this.$t("hsCodes.tablets") },
        { text: this.$t("hsCodes.toys"), value: this.$t("hsCodes.toys") },
        { text: this.$t("hsCodes.watches"), value: this.$t("hsCodes.watches") },
        { text: this.$t("hsCodes.others"), value: this.$t("hsCodes.others") },
      ];
    },
    HS_CODES() {
      return {
        [this.$t("hsCodes.acessoryNoBattery")]: 903289,
        [this.$t("hsCodes.acessory")]: 851822,
        [this.$t("hsCodes.audioVideo")]: 711719,
        [this.$t("hsCodes.bagsLuggages")]: 420299,
        [this.$t("hsCodes.boardGames")]: 950300,
        [this.$t("hsCodes.booksCollectibles")]: 490199,
        [this.$t("hsCodes.cameras")]: 852580,
        [this.$t("hsCodes.computersLaptops")]: 847130,
        [this.$t("hsCodes.documents")]: 490199,
        [this.$t("hsCodes.dryFoodsSupplements")]: 210690,
        [this.$t("hsCodes.fashion")]: 430310,
        [this.$t("hsCodes.gaming")]: 950430,
        [this.$t("hsCodes.healthBeauty")]: 330499,
        [this.$t("hsCodes.homeAppliances")]: 851640,
        [this.$t("hsCodes.homeDecor")]: 442090,
        [this.$t("hsCodes.jewelry")]: 711719,
        [this.$t("hsCodes.mobilePhone")]: 851712,
        [this.$t("hsCodes.petAccessory")]: 420100,
        [this.$t("hsCodes.shoes")]: 640590,
        [this.$t("hsCodes.sportLeisures")]: 420291,
        [this.$t("hsCodes.tablets")]: 847130,
        [this.$t("hsCodes.toys")]: 950300,
        [this.$t("hsCodes.watches")]: 910219,
      };
    },
    allFields() {
      return [
        {
          key: "sku",
          label: this.$t("sku"),
          sortable: true,
          helpText: this.$t("productsPage.skuHelptext"),
        },
        {
          key: "name",
          label: this.$t("name"),
          sortable: true,
          helpText: this.$t("productsPage.nameHelptext"),
        },
        {
          key: "origin_country",
          label: this.$t("originCountry"),
          sortable: true,
        },
        {
          key: "weight",
          label: `${this.$t("weight")} (${this.measurementSystem.weight})`,
          sortable: true,
        },
        {
          key: "dimensions",
          label: `${this.$t("dimensions")} (${this.$t(
            "abbreviatedWidth"
          )} x ${this.$t("abbreviatedHeight")} x ${this.$t(
            "abbreviatedLength"
          )})`,
          sortable: true,
          helpText: this.$t("productsPage.dimensionsHelptext"),
        },
        {
          key: "category",
          label: this.$t("category"),
          sortable: true,
          helpText: this.$t("productsPage.categoryHelptext"),
        },
        {
          key: "hs_code",
          label: this.$t("hsCode"),
          sortable: true,
          helpText: this.$t("productsPage.hs_codeHelptext"),
          helpTextLink: {
            href: "https://www.trade.gov/harmonized-system-hs-codes",
            text: "Here >",
          },
        },
        {
          key: "description",
          label: this.$t("description"),
          sortable: true,
          helpText: this.$t("productsPage.descriptionHelptext"),
        },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("quickActions"),
          class: "right--20 right-sticky",
        },
      ];
    },
    productsOptions() {
      return [
        {
          text: this.$t("productsPage.deleteProduct"),
          value: "#modalMassiveDeleteProductTitle",
        },
        {
          text: this.$t("productsPage.downloadProducts"),
          value: "massiveDownload",
        },
      ];
    },
  },
  watch: {
    "new_product.category"(newValue) {
      if (newValue !== "Others") {
        this.new_product.hs_code = this.hs_codes[newValue];
      }
    },
  },
};
</script>

<style scoped>
.heading-page .left-side {
  max-width: 32rem;
  margin-right: 0.75rem;
  margin-bottom: 2.8rem;
}
.heading-page .right-side {
  width: 15.9rem;
  margin-bottom: 2.8rem;
}
</style>
